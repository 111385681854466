<template>
  <div class="device">
    <titlePage :item="title"></titlePage>
    <div class="device-box">
      <div class="device-list">
        <img :src="img.visual.tunnel.gateway" alt="" />
        <div class="device-con device-con-bor">
          <div class="device-itme">
            <span class="device-name">网关总数</span>
            <span class="device-num">{{totalData.gatewayCount}}</span>
          </div>
          <div class="device-itme">
            <span class="device-name">在线数量</span>
            <span class="device-num">{{totalData.gatewayOnline}}</span>
          </div>
          <div class="device-itme">
            <span class="device-name">离线数量</span>
            <span class="device-num">{{totalData.gatewayOffline}}</span>
          </div>
        </div>
      </div>
      <div class="device-list">
        <img :src="img.visual.tunnel.controller" alt="" />
        <div class="device-con device-con-bor">
          <div class="device-itme">
            <span class="device-name">控制器总数</span>
            <span class="device-num">{{totalData.lightCount}}</span>
          </div>
          <div class="device-itme">
            <span class="device-name">在线数量</span>
            <span class="device-num">{{totalData.lightOnline}}</span>
          </div>
          <div class="device-itme">
            <span class="device-name">报警数量</span>
            <span class="device-num">{{totalData.lightAlarm}}</span>
          </div>
        </div>
      </div>
      <div class="device-list">
        <img :src="img.visual.tunnel.lamp" alt="" />
        <div class="device-con">
          <div class="device-itme">
            <span class="device-name">光源总数</span>
            <span class="device-num">{{totalData.lampCount}}</span>
          </div>
          <div class="device-itme">
            <span class="device-name">亮灯数量</span>
            <span class="device-num">{{totalData.lampRunning}}</span>
          </div>
          <div class="device-itme">
            <span class="device-name">灭灯数量</span>
            <span class="device-num">{{totalData.lampNotRunning}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from "./title";
export default {
  name: "device",
  components: {
    titlePage,
  },
  data() {
    return {
      title: {
        cn: "设备统计",
        en: "Equipment statistics",
      },
      totalData: {
        "gatewayCount": 0,
        "gatewayOnline": 0,
        "gatewayOffline": 0,
        "lightCount": 0,
        "lightOnline": 0,
        "lightOffline": 0,
        "lightAlarm": 0,
        "lightNotAlarm": 0,
        "lightRunning": 0,
        "lightNotRunning": 0,
        "lampCount": 0,
        "lampRunning": 0,
        "lampNotRunning": 0
      }
    };
  },
  computed: {},
  beforeCreate: function () { },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init: function () {
      this.$axios.post(`device/light/QueryDeviceTotal`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
        }
      });
    },
  },
};
</script>
<style scoped>
.device {
  margin-top: 30px;
  width: 100%;
}
.device-list {
  display: flex;
  margin: 20px 0;
}
.device-list img {
  width: 56px;
  height: 65px;
}
.device-con.device-con-bor {
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(6, 13, 24, 1) 0%,
      rgba(39, 67, 93, 1) 50%,
      rgba(6, 13, 24, 1) 100%
    )
    1;
}
.device-con {
  display: flex;
  align-items: center;
  width: calc(100% - 56px);
  text-indent: 20px;
}
.device-itme {
  display: flex;
  flex-direction: column;
  width: 33%;
}
.device-name {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #9fc6e0;
}
.device-num {
  font-family: Arial;
  font-weight: bold;
  font-size: 22px;
  color: #f6fafd;
  text-shadow: 0px 0px 9px #0e45ae;
}
</style>
