<template>
  <div class="headPage" :style="{ backgroundImage: `url(${img.visual.tunnel.topAi})` }">
    <div class="head-left">
      <img :src="img.visual.tunnel.location" alt="" />
      <div class="left-txt">{{weather.cityName}}</div>
      <div class="left-txt ml10">天气:</div>
      <div class="left-txt ml10">{{weather.degree}}℃ {{weather.type}}</div>
    </div>
    <div class="head-title">
      <!-- <img :src="img.visual.tunnel.lesanglogo" alt="" />
      <span> 乐广高速隧道照明管理系统 </span> -->
      <img v-if="config && config.appLogo && config.appLogo.length == 32" :src="`${domains.baseURL}sys/setting/FileContent?id=${config.appLogo}&auth=${token}`" />

    </div>
    <div class="head-right">
      <div class="right-time">{{time}}</div>
      <div class="right-date">
        <span>{{weeks[week]}}</span>
        <span>{{date}}</span>
      </div>
      <div>
        <img :src="img.visual.tunnel.close" alt="" @click="screenfull()" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "headPage",
  components: {},
  data() {
    return {
      date: '',
      time: '',
      week: '',
      weather:{
        degree:'',
        type:'',
        cityName:'',
      },
      config: {},
    };
  },
  computed: {
    ...mapState("auth", ["token", "user", "appTypes"]),
    ...mapState('common', ['weeks']),
  },
  beforeCreate: function () { },
  created: function () { },
  mounted: function () {
    this.setDateTime();
    this.initMenus();
    this.getWeather();
  },
  methods: {
    initMenus: function () {
      this.$axios.post(`common/auth/QueryAllMenus`, {}).then(res => {
        if (res.code !== 0) return;
        this.$set(this, 'config', res.data.config);
      });
    },
    setDateTime: function () {
      let now = new Date();
      this.date = now.format('yyyy-MM-dd');
      this.time = now.format('HH:mm:ss');
      this.week = now.getDay();
      setTimeout(this.setDateTime, 1000);
    },
    //获取天气
    getWeather: async function () {
      let result = await this.$axios.post(`//${this.domains.trans}/station/base/QueryWeather`, {})
      if (result.code == 0) {
        let city = result.data
        this.weather.degree = result.data.temperature //天气
        this.weather.type = city.weather
        this.weather.cityName = city.cityName
      } else {
        this.weather.degree = '--'
        this.weather.type = '--'
        this.weather.cityName = '--'
      }
    },
    screenfull() {
      this.$emit('screenfullExit', false);
    }
  },
};
</script>
<style scoped>
.headPage {
  width: 100%;
  height: 75px;
  background-size: 100% 100%;
  display: flex;
  justify-content: space-between;
}
.head-left {
  display: flex;
  justify-content: space-between;
  margin-left: 50px;
  justify-content: space-evenly;
  align-items: center;
  width: 252px;
}
.head-left img {
  width: 16px;
  height: 19px;
  display: inline-block;
}
.left-txt {
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.head-title{
  line-height: 75px;
  text-align: center;
}
.head-title img {
  vertical-align: middle;
  max-width: 485px;
  max-height: 70px;
}
.head-right {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
  align-items: center;
  width: 252px;
  color: #feffff;
}
.right-time {
  font-family: Helvetica;
  font-weight: 400;
  font-size: 34px;
  color: #fcfcfd;
}
.right-date {
  font-family: Source Han Sans SC;
  font-weight: 400;
  font-size: 12px;
  color: #fcfcfd;
  display: flex;
  flex-direction: column;
}
.head-right img {
  cursor: pointer;
}
</style>
