<template>
  <div class="lampPolice">
    <titlePage :item="title"></titlePage>
    <div class="lampPolice-box">
      <div class="lampPolice-left">
        <div>
          <chartsPie :option="pieData" id="lampPoliceBox" class="lampPolice-pie" :key="1"></chartsPie>
        </div>
        <div class="lampPolice-total">
          <div class="total-num">{{total}}</div>
          <div class="total-title">报警数量</div>
        </div>
      </div>
      <div class="lampPolice-right">
        <div class="lampPolice-list" v-for="el,index in pieData.series" :key="index">
          <div class="lampPolice-block" :style="{background:pieData.color[index]}"></div>
          <div class="list-box">
            <span class="lampPolice-name" :style="{color:pieData.color[index]}">{{el.name}}</span><i class="lampPolice-num" :style="{color:pieData.color[index]}">{{el.value}}</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titlePage from "./title";
// import * as echarts from 'echarts';
export default {
  name: "lampPolice",
  components: {
    titlePage,
  },
  data() {
    return {
      title: {
        cn: "单灯报警 ",
        en: "Single light alarm",
      },
      pieData: {
        color: ['#5A8DBC', '#7988D2', '#B68148', '#D6B981', '#B6C5D1'],
        series: [
          { value: 0, name: '' },
        ],
        setSeries: {
          label: {
            show: false,
            position: 'center'
          },
          radius: ['55%', '90%'],
          itemStyle: {
            borderRadius: 10,
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2
          }
        }
      },
      totalData: [],
      total: 0,
    };
  },
  computed: {},
  beforeCreate: function () {
  },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/light/QueryAlarmAnalyseType`, {}).then(res => {
        if (res.code == 0) {
          let arr = Object.entries(res.data).map(([key, value]) => [key, value])
          this.$set(this, 'totalData', arr);
          this.total = 0;
          this.handleData();
        }
      });
    },
    handleData() {
      this.pieData.series = [];
      this.totalData.forEach(el => {
        this.pieData.series.push({ value: el[1], name: el[0] })
        this.total = this.total + el[1];
      });
    }
  },
};
</script>
<style scoped>
.lampPolice {
  margin-top: 22px;
  width: 100%;
}
.lampPolice-box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.lampPolice-left {
  width: 250px;
  height: 250px;
  position: relative;
}
.lampPolice-total {
  position: absolute;
  top: 50%;
  width: 250px;
  text-align: center;
  margin-top: -50px;
}
.lampPolice-pie {
  width: 250px;
  height: 250px;
}
.lampPolice-right {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.lampPolice-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.list-box {
  display: flex;
  margin-left: 15px;
  width: 100px;
  justify-content: space-between;
}
.total-num {
  font-family: Arial;
  font-weight: bold;
  font-size: 38px;
  color: #ffffff;
  text-shadow: 0px 0px 22px #0e398c;
  margin-top: 10px;
}
.total-title {
  font-family: Microsoft YaHei;
  font-weight: bold;
  font-size: 18px;
  color: #9fc6e0;
}
.lampPolice-block {
  width: 12px;
  height: 12px;
}
.lampPolice-name {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 16px;
}
.lampPolice-num {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 18px;
  font-style: normal;
}
</style>
