<template>
  <div class="herderTop">
    <div class="herder-box" :style="{ backgroundImage: `url(${img.visual.tunnel.outside})` }">
      <p class="herder-title">{{outerData.name}}洞外照度趋势</p>
      <p class="herder-num">{{outerData.num}} <i class="herder-unit">cd/㎡</i></p>
    </div>
    <div class="herder-box" :style="{ backgroundImage: `url(${img.visual.tunnel.inside})` }">
      <p class="herder-title">{{innerData.name}}洞内照度趋势</p>
      <p class="herder-num">{{innerData.num}} <i class="herder-unit">Lux</i></p>
    </div>
  </div>
</template>
<script>
export default {
  name: "herderTop",
  components: {
  },
  data() {
    return {
      title: {
        cn: "",
        en: "",
      },
      totalData:[],
      setTimer: null,
      innerData:{
        num:'',
        name:''
      },
      outerData:{
        num:'',
        name:''
      },
      innerNum: 0,
      outerNum: 0
    };
  },
  computed: {},
  beforeCreate: function () {
    clearInterval(this.setTimer);
    this.setTimer=null;
  },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/lux/QueryDeviceCurrent`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
          this.handleTotal();

        }
      });
    },
    handleTotal() {
      this.totalData.inner.forEach((el, index) => {
        if (index == this.innerNum) {
          this.innerData.name=el.name
          this.innerData.num=el.lux
        }
      });
      this.totalData.outer.forEach((el, index) => {
        if (index == this.outerNum) {
         this.outerData.name=el.name
         this.outerData.num=el.lux
        }
      });
      this.setTimer = setTimeout(() => {
        this.handleTotal();
        this.innerNum++;
        this.outerNum++;
        if (this.innerNum == this.totalData.inner.length) {
          this.innerNum = 0;
        }
        if (this.outerNum == this.totalData.outer.length) {
          this.outerNum = 0;
        }
      }, 4000);
    }
  },
};
</script>
<style scoped>
.herderTop {
  margin-top: 30px;
  display: flex;
  width: 100%;
  justify-content: space-evenly;
}
.herder-box {
  display: flex;
  flex-direction: column;
}
.herder-box {
  width: 231px;
  height: 70px;
}
.herder-title {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 13px;
  color: #9fc6e0;
  margin-top: 10px;
  text-indent: 75px;
}
.herder-num {
  font-family: Arial;
  font-weight: bold;
  font-size: 21px;
  color: #f6fafd;
  line-height: 22px;
  margin-top: 2px;
  text-indent: 75px;
}
.herder-unit {
  font-family: Microsoft YaHei;
  font-weight: 400;
  font-size: 15px;
  color: #38ccd3;
  font-style: normal;
}
</style>
