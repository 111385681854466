<template>
  <div class="lightRate">
    <titlePage :item="title"></titlePage>
    <div class="lightRate-box">
      <chartsLine :option="lineData" id="lightRateBox"></chartsLine>
    </div>
  </div>
</template>
<script>
import titlePage from "./title";
import * as echarts from 'echarts';
export default {
  name: "lightRate",
  components: {
    titlePage,
  },
  data() {
    return {
      title: {
        cn: "亮灯率",
        en: "Light on rate",
      },
      lineData: {
        color: ["#41EBF3"],
        title: "",
        x: [],
        unit: "%",
        series: [
          {
            name: "亮灯率(%)",
            data: [],
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(65, 235, 243, 0.9)'
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255,0.1)'
              }
            ])
          }, //下阴影
        },
        cover: {
          legend: {
            padding: [5, 10],
            top: '5%',
            data: []
          },
        }
      },
      setTimer: null,
      setRefresh: 0,
      totalData: [],
    };
  },
  computed: {},
  beforeCreate: function () {
    clearInterval(this.setTimer);
    this.setTimer = null;
  },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      let ajaxData = {
        "start": "",
        "end": "",
      }
      let now = new Date();
      ajaxData.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 7);
      ajaxData.start = now.format('yyyy-MM-dd HH:mm:ss');

      this.$axios.post(`device/light/QueryLightOpenRate`, ajaxData).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
          this.handleTotal();

        }
      });
    },
    handleTotal() {
      this.totalData.forEach(el => {
          this.lineData.x.push(el.date)
          this.lineData.series[0].data.push(el.rate)
      });
    }
  },
};
</script>
<style scoped>
.lightRate {
  margin-top: 30px;
  width: 100%;
}
.lightRate-box {
  width: 100%;
  height: 260px;
}
</style>
