<template>
  <div class="carousel">
    <Carousel autoplay v-model="value" loop height='auto'>
      <CarouselItem>
        <div class="demo-carousel"><img :src="img.visual.tunnel.tunnelModel" alt=""></div>
        <!-- tunnelModel -->
        <!-- tunnelModelJust loop-->
      </CarouselItem>
      <CarouselItem>
        <div class="demo-carousel"> <img :src="img.visual.tunnel.tunnelModelJust" alt=""></div>
      </CarouselItem>
      <CarouselItem>
        <div class="demo-carousel"> <img :src="img.visual.tunnel.tunnelModelSide" alt=""></div>
      </CarouselItem>
    </Carousel>
  </div>
</template>
<script>
export default {
  name: "carousel",
  components: {
  },
  data() {
    return {
      value: 0
    };
  },
  computed: {},
  beforeCreate: function () { },
  created: function () { },
  mounted: function () { },
  methods: {},
};
</script>
<style scoped>
.carousel {
  margin-top: 30px;
  width: 100%;
  height: 100%;
}
.demo-carousel {
  height: 100%;
  /* display: flex;
  align-items: center; */
}
.demo-carousel img {
  vertical-align: middle;
}
/* >>> .ivu-carousel-list {
  height: 100%;
  display: flex;
  align-items: center;
} */
</style>
