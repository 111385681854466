<template>
  <div class="energy">
    <titlePage :item="title"></titlePage>
    <div class="energy-box">
      <chartsBar :option="barData" id="energyBox"></chartsBar>
    </div>
  </div>
</template>
<script>
import titlePage from "./title";
import * as echarts from 'echarts';
export default {
  name: "energy",
  components: {
    titlePage,
  },
  data() {
    return {
      title: {
        cn: "能耗趋势",
        en: "Traffic flow monitoring",
      },
      barData: {
        color: ["#DEB475"],
        title: "",
        x: [],
        unit: "Lux",
        series: [
          {
            name: "能耗趋势(Lux)",
            data: [],
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#83bff6' },
              { offset: 0.5, color: '#188df0' },
              { offset: 1, color: '#188df0' }
            ])
          },
          emphasis: {
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: '#2378f7' },
                { offset: 0.7, color: '#2378f7' },
                { offset: 1, color: '#83bff6' }
              ])
            }
          },
        },
        cover: {
          legend: {
            padding: [5, 10],
            top: '5%',
            data: []
          },
        }
      },
      setTimer: null,
      setRefresh: 0,
      totalData: [],
    };
  },
  computed: {},
  beforeCreate: function () {
  },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      let ajaxData = {
        "start": "",
        "end": "",
      }
      let now = new Date();
      ajaxData.end = now.format('yyyy-MM-dd HH:mm:ss');
      now.setDate(now.getDate() - 7);
      ajaxData.start = now.format('yyyy-MM-dd HH:mm:ss');

      this.$axios.post(`device/light/QueryLampEnergyTrend`, ajaxData).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
          this.handleTotal();

        }
      });
    },
    handleTotal() {
      this.totalData.forEach(el => {
          this.barData.x.push(el.time)
          this.barData.series[0].data.push(el.value)
      });
    }
  },
};
</script>
<style scoped>
.energy {
  margin-top: 22px;
  width: 100%;
}
.energy-box {
  width: 100%;
  height: 260px;
}
</style>
