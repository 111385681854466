<template>
  <div class="home-container" id="autofitBox" ref="autofitBox" data-autofit :style="{ backgroundImage: `url(${img.visual.tunnel.bg})` }">
    <div class="head">
      <headPage @screenfullExit='screenfullExit'></headPage>
    </div>
    <div class="home-con">
      <div class="home-left">
        <devicePage></devicePage>
        <outsidePage></outsidePage>
        <InsidePage></InsidePage>
      </div>
      <div class="home-middle">
        <herderPage></herderPage>
        <carouselPage class="home-middle-carousel"></carouselPage>
        <energyPage></energyPage>
      </div>
      <div class="home-right">
        <lightRatePage></lightRatePage>
        <lampPolice></lampPolice>
        <policePage></policePage>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import autofit from 'autofit.js'
import screenfull from 'screenfull/dist/screenfull'

import headPage from './module/head'
import devicePage from './module/device'
import outsidePage from './module/outside'
import InsidePage from './module/Inside'
import herderPage from './module/herder'
import carouselPage from './module/carousel'
import energyPage from './module/energy'
import lightRatePage from './module/lightRate'
import policePage from './module/police'
import lampPolice from './module/lampPolice'

export default {
  name: 'tunnelHome',
  components: {
    headPage,
    devicePage,
    outsidePage,
    InsidePage,
    herderPage,
    carouselPage,
    energyPage,
    lightRatePage,
    lampPolice,
    policePage,
  },
  data() {
    return {
      isFullscreen: false
    }
  },
  watch: {

  },
  computed: {
    ...mapState('auth', ['token', 'user', 'appTypes']),
  },
  beforeCreate: function () {
    // autofit.off();
  },
  created: function () {
  },
  mounted: function () {
    this.isScreenfull()
    this.init()
  },
  methods: {
    setAutofit() {
      // console.log('autofit---------------')
      // console.log(autofit)
      autofit.init({
        dh: 1080,
        dw: 1920,
        el: '#autofitBox',
        resize: true,
        // ignore:['.center-middle']
      }, false)
    },
    screenfullExit() {
      screenfull.exit(this.$refs.autofitBox);
      this.$store.commit('auth/setScreenfullCode', "StateViewTunnel");
    },
    init() {
      if (screenfull.isEnabled) {
        screenfull.on('change', this.changeFullscreen);
      }
    },
    changeFullscreen() {
      this.isFullscreen = screenfull.isFullscreen;
      if (this.isFullscreen == false) {
        this.$store.commit('auth/setScreenfullCode', "StateViewTunnel");
      }
    },
    isScreenfull() {
      if (!screenfull.isEnabled) {
        this.$Message({
          message: '浏览器版本低不支持全屏',
          type: 'warning'
        })
        return false
      }
      screenfull.toggle(this.$refs.autofitBox)
      this.setAutofit()
    },
  }
}
</script>
<style scoped>
.home-container {
  width: calc(100% - 100px);
  height: calc(100%);
  margin: 0;
  display: flex;
  flex-direction: column;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: #060d18;
}
.home-con {
  padding: 0 50px;
  display: flex;
  justify-content: space-between;
}
.home-left,
.home-right {
  width: 427px;
}
.home-middle {
  width: 886px;
}
.home-middle-carousel {
  height: calc(100% - 453px);
}
</style>
