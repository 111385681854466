<template>
  <div class="police">
    <titlePage :item="title"></titlePage>
    <div class="police-box">
        <table>
          <tr class="tab-th">
            <td>序号</td>
            <td>设备</td>
            <td>状态</td>
            <td>时间</td>
          </tr>
          <tr v-for="el,index in tabData" :key="el.id" class="tab-td">
            <td><i class="tab-block">{{index+1}}</i></td>
            <td :title="el.lampName">{{el.lampName}}</td>
            <td><i class="tab-red" :title="el.name">{{el.name}}</i></td>
            <td>
              <span class="tdStartTime">
                {{handleData(el.startTime)}}
              </span>
            </td>
          </tr>
        </table>
    </div>
  </div>
</template>
<script>
import titlePage from "./title";
export default {
  name: "police",
  components: {
    titlePage,
  },
  data() {
    return {
      title: {
        cn: "实时报警",
        en: "Real time alarm",
      },
      totalData:[],
      tabData:[]
    };
  },
  computed: {},
  beforeCreate: function () {
  },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/light/QueryCurrentAlarm`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
          this.$set(this, 'tabData', res.data.list);
        }
      });
    },
    handleData(el) {
      return el.substring(5,28)
    }
  },
};
</script>
<style scoped>
.police {
  margin-top: 30px;
  width: 100%;
}
.police-box{
  width: 100%;
  height: 270px;
  overflow-y: auto;
}
.tab-th td{
  background-color: rgba(47, 75, 102, .4);
  height: 33px;
  color: #CFE5FF;
  text-align: center;
}
.tab-td td{
  background-color: rgba(47, 75, 102, 0.2);
  height: 33px;
  color: #CFE5FF;
  text-align: center;
}
.police-box table{
  width: 100%;
  margin-top: 10px;
}
.tab-red{
  color: red;
}
.tdStartTime{
  width: 98px;
  display: inline-block;
}
</style>
