<template>
  <div class="outside">
    <titlePage :item="title"></titlePage>
    <div class="outside-box">
      <chartsLine :option="lineData" id="outsideBox" :key="setRefresh"></chartsLine>
    </div>
  </div>
</template>
<script>
import titlePage from "./title";
import * as echarts from 'echarts';
export default {
  name: "outside",
  components: {
    titlePage,
  },
  data() {
    return {
      title: {
        cn: "洞外照度趋势",
        en: "Trend of illumination outside the cave",
      },
      totalData: {},
      lineData: {
        color: ["#DEB475"],
        title: "",
        x: [],
        unit: "Lux",
        series: [
          {
            name: "",
            data: [],
          }
        ],
        setSeries: {
          smooth: true, //平滑折线图
          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: 'rgba(222, 180, 117,0.9)'
              },
              {
                offset: 1,
                color: 'rgba(255, 255, 255,0.1)'
              }
            ])
          }, //下阴影
        },
        cover: {
          legend: {
            padding: [5, 10],
            top: '5%',
            data: []
          },
        }
      },
      setTimer: null,
      setRefresh: 0
    };
  },
  computed: {},
  beforeCreate: function () {
    clearInterval(this.setTimer);
    this.setTimer = null;
  },
  created: function () { },
  mounted: function () {
    this.init()
  },
  methods: {
    init() {
      this.$axios.post(`device/lux/QueryDeviceCurrent`, {}).then(res => {
        if (res.code == 0) {
          this.$set(this, 'totalData', res.data);
          this.handleTotal();

        }
      });
    },
    handleTotal() {

      this.totalData.outer.forEach((el, index) => {
        if (index == this.setRefresh) {
          if(el.history.legend==0){
            this.setRefresh++
            return false
          }
          this.lineData.series = [];
          this.lineData.x = [];
          this.lineData.series.push({ name: "", data: [], })
          this.lineData.series[0].name = el.name + '(Lux)'
          el.history.forEach(ele => {
            this.lineData.x.push(ele.time)
            this.lineData.series[0].data.push(ele.lux)
          });
        }
      });
      this.setTimer = setTimeout(() => {
        this.handleTotal();
        this.setRefresh++;
        if (this.setRefresh == this.totalData.outer.length) {
          this.setRefresh = 0;
        }
      }, 4000);
    }
  },
};
</script>
<style scoped>
.outside {
  margin-top: 30px;
  width: 100%;
}
.outside-box {
  width: 100%;
  height: 260px;
}
</style>
